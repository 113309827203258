/* ---------- root variables ---------- */
:root {
    --green: rgb(38, 76, 89);
    --light-green: rgb(172, 198, 201);
    --light-grey: #f1f1f4;
    --text-color-light: #f1f1f1;
    --text-color-dark: #333;
    --text-color-danger: rgb(152, 58, 58);
    --white: #f1f1f1;
}

body {
    color: var(--text-color-dark);
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
html{
    overflow-x: hidden;
}

main {
    padding: 58px 0 0;
    background-color: var(--light-green);
    width: 100vw;
    margin: 0;
}

h1 {
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 0.1px;
    width: fit-content;
}

h2 {
    font-weight: 500;
    letter-spacing: 0.1px;
}

h3 {
    font-weight: 500;
    letter-spacing: 0.1px;
}

a {
    color: var(--green);
}

.page {
    background-color: var(--light-green);
    min-height: calc(100vh - 58px - 2em);
    padding: 1em 0 1em 1em;
}

.text-center {
    text-align: center;
}
.text-error {
    color: var(--text-color-danger);
}

.text-error:first-letter {
    text-transform: uppercase;
}

/* ---------- animations ---------- */
/* fade in */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* ---------- nav styling ---------- */
nav {
    background-color: var(--green);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 100vw;
}

/* Style the links inside the navigation bar */
nav a {
    display: inline-block;
    color: var(--text-color-light);
    text-align: center;
    padding: 20px 16px;
    text-decoration: none;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

/* Change the color of links on hover */
nav a:hover {
    background-color: var(--light-grey);
    color: var(--text-color-dark);
}

/* Add a color to the active/current link */
nav a.active {
    background-color: var(--light-green);
    color: var(--green);
}

/* ---------- grid container styling ---------- */
.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
}

@media (min-width: 600px) {
    .grid-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 992px) {
    .grid-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.grid-container > article {
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 1.5em;
    background-color: var(--white);
    transition: 0.5s;
    animation: fadeIn 0.5s;
    cursor: pointer;
}

.grid-container > article ::first-letter {
    text-transform: uppercase;
}

.grid-container > article:hover {
    box-shadow: 0 8px 16px 0 var(--green);
}

.grid-container > article img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

/* ---------- form styling ---------- */

form {
    margin: auto;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 1em 1em 2em;
    background-color: var(--white);
    max-width: 380px;
    animation: fadeIn 0.5s;
}

form button {
    padding: 12px 15px;
    font-size: 1em;
    width: 100%;
    max-width: 350px;
}

button {
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    border: none;
    color: var(--text-color-light);
    background-color: var(--green);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0.5em 1em;
    font-size: 0.8em;
    display: block;
    margin: 1em auto 0;
}

button.btn-outline {
    background-color: var(--light-green);
    color: var(--green);
    border: solid 2px var(--green);
}

.btn-sign-out {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 0.7em;
    padding: 2.032em 1em;
}

input {
    margin: 1em auto;
    width: 100%;
    max-width: 350px;
    padding: 1em;
    box-sizing: border-box;
    display: block;
    background: var(--white);
    border: solid 2px var(--green);
}

.image-preview {
    width: 100%;
    max-width: 350px;
    height: 200px;
    object-fit: cover;
    margin: auto;
    display: block;
    cursor: pointer;
}

form .file-input {
    display: none;
}

/* ---------- loader styling ---------- */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    overflow: hidden;
    background: var(--loader-background-color);
    text-align: center;
}

.spinner {
    border: 5px solid var(--light-green);
    border-radius: 50%;
    border-top: 5px solid var(--green);
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.4s, opacity 0.4s linear;
}

.avatar {
    display: flex;
    align-items: center;
    margin: 0 0 1.2em;
    text-align: left;
    width: 100vw;
}

.avatar span {
    margin: 0 0 0 1em;
}

.avatar h3,
.avatar p {
    margin: 0;
}

article .avatar img {
    max-width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.flexbox {
    display: block;
    width: fit-content;

}

.lists{
    display: flex;
}

.film, .serie {
    width: 600px;
    margin: 10px;
    padding: 10px;
    background-color: white;
    cursor: pointer;
}
.noserie, .nofilm{
    display: none;
}

.filter{
    display: flex;
    font-size: 20px;
}

.filter input{
    margin: 0 10px 0 10px;
    width: 20px;
    bottom: 0;
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20pxn;
}

.ident {
    font-size: 10px;
}
.type{
    width: 100%;
}

.type > select{
    margin: 10px 0 0 10px;
    width: 100px;
    height: 30px;
}